.footer {
    a {
        color: $white;

        &:hover {
            color: $purple;
        }
    }
    i{
        font-size: 1.5rem;
    }
}

.logoVacio {
    max-width: 100px;
}