.btnBlue {
    padding: 10px 15px;
    border-radius: 10px;
    background-color: $blueDark;
    color: $white;

    &:hover {
        background-color: $purple;
        color: $white;
    }
}

.btnUP {
    background: $white;
    position: fixed;
    bottom: 15vh;
    right: 3vh;
    box-shadow: 0 0 10px $black;
    color: $blueDark;
    width: 50px;
    height: 50px;
    padding: 5px;
    border-radius: 50px;
    line-height: 1;
}

.btn-linear-blue{
    border-color: $blueDyrty;
}