#partners-caousel {
    img {
        max-height: 150px;
    }
}

.carousel-control-prev,
.carousel-control-next {
    text-shadow: 0 0 5px $black;
    font-size: 2rem;
}

.bg-Parner-Title-Basic,
.bg-Parner-Title-Standart,
.bg-Parner-Title-Premium {
    background: $bgBlackToGreyBTM;
    padding: 10px 0;
}

.bg-Parner-Title-Standart {
    background: $bgLinearBlue;
}

.bg-Parner-Title-Premium {
    background: $goldGradiant;
}

.list-paket-parners ul li {
    padding: 10px 0;
    border-bottom: 2px solid rgba($blueDyrty, .5);
    color: $blueDyrty;
}

.list-paket-parners ul li:hover {
    background-color: $turque;
    color: $white;
}