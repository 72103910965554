.header {
    min-height: 15vh;
    i{
        font-size: 1.5rem;
    }
}

.menuMovil {
    position: absolute;
    width: 10%;
    top: 0;
    right: 5%;
    z-index: 153;
    font-size: 23px;
}

.linksMenu {
    position: absolute;
    background: $white;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    padding-top: 25%;
    z-index: 1235;
}