@import 'mixin/mixin.scss';

body {
  margin: 0;
  font-family: 'Passion One', cursive;
  font-family: 'Teko', sans-serif;
  font-size: 1.1rem;
}

a {
  color: $blue;

  &:hover {
    color: $purple;
  }
}

.nav-link:hover,
.nav-link:focus {
  color: $purple;
}

.minH50vh {
  min-height: 50vh;
}

.slider {
  min-height: 85vh;
  @include bgImage;
  color: $white;
  text-shadow: 0 0 5px $black;
  transition: all .5s ease-in-out;
}

.sliderHome {
  background-image: url('../img/sliders/sliderHomePrueba.jpg');

  a {
    &:hover {
      text-shadow: none;
      color: $black;
    }
  }
}

.sliderAmerica {
  background-image: url('../img/america-map.jpg');
}

@media only screen and (max-width: 600px) {
  .sliderAmerica {
    background-image: url('../img/america-map.jpg');
    background-position: 5%;
  }
}

@media only screen and (max-width: 1024px) {
  .sliderAmerica {
    background-image: url('../img/america-map.jpg');
    background-position: 10%;
  }
}


.sliderEcommerce {
  background-image: url('../img/sliders/sliderHomeEcommerce.jpg');
}

.manoSlider {
  background-image: url('../img/sliders//mano.png');
  width: 100%;
  position: absolute;
  animation: backEfect 3s infinite;
}

@keyframes backEfect {
  0% {
    background-position: 25px;
  }

  10% {
    background-position: 50%;
  }

  90% {
    background-position: 50%;
  }

  100% {
    background-position: 25px;
  }
}

.sliderAbout {
  background-image: url('../img/sliders/sliderAbout.jpg');
}

.sliderContact {
  background-image: url('../img/sliders/sliderContact.jpg');
}

.sliderFaqs {
  background-image: url('../img/sliders/sliderFaqs.jpg');
}

.sliderServicios {
  background-image: url('../img/sliders/sliderServicios.jpg');
}

.sliderStore {
  background-image: url('../img/contrucction.jpg');
}

.sliderError {
  background-image: url('../img/404.jpg');
}

.bgLinearBlue {
  background: $bgLinearBlue;
}
.bgBlue{
  background: $blue;
}

.bgDarkBlue {
  background-color: $blueDark;
}

.bgBlueKing{
  background: $blueKing;
}
.bgLightGrey {
  background-color: $lightGrey;
}

.bgBlueTrans {
  background-color: rgba($blueDark, .8);
}

.bgTurque {
  background-color: lighten($blueKing, 20)
}

.bgPurple {
  background-color: $purple;
}

.bgBlueDyrty {
  background-color: $blueDyrty;
}

.bgDirtyTrans {
  background-color: rgba($blueDyrty, .3);
}

.shadowCool {
  box-shadow: -13px -13px 14px -4px rgb(255, 255, 255), 3px 3px 15px 4px rgba(184, 184, 184, 66%);
}

input,textarea{
  border: 1px solid rgba($grey, .3);  
}