#pills-tab, #pills-tab-content1,#pills-tab-content2 {
    color: $blueDark;

    i {
        color: $white;
    }

    .active {
        background-color: lighten($blueKing, 20) !important;
        color: $white;

        i {
            color: $white;
        }
    }
}

.selectService {
    color:$white;
    padding:5px 20px;
    transition: all .2s ease-in-out;
    border-bottom: 2px solid rgba($turque, .3);

    &:hover {
        background-color: lighten($blueKing, 30);
        color: $white;
    }
}
.selectServiceTitle{
    padding:5px 20px;
    color: $white;
    font-weight: 700;
    background-color: lighten($blueKing, 10)
}
#CallCenter {
    #pills-tab {
        .active {
            background-color: $turque;
            color: $white;
        }
    }
}
