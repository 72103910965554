@import '../mixin/mixin.scss';

.services {
    min-height: 500px;
}

.clientsBg{
    min-height: 75vh;
    background-image: url('../../img/clientsBg.jpg');
    background-color: #001621;
    @include bgImage;
}


.callCenter {
    background-image: url('../../img/callCenter.jpg');
}

.outsourcing {
    background-image: url('../../img/outsourcing.jpg');
}

.tecnologia {
    background-image: url('../../img/tecnologia.jpg');
}

.optimizacion {
    background-image: url('../../img/optimizacion.jpg');
}

.digital {
    background-image: url('../../img/digital.jpg');
}

.SulLLave {
    min-height: 40vh;
}

.bgPaket {
    background-color: $blueDyrty;
    padding: 10px 0;
}

.list-paket-home ul li {
    padding: 10px 0;
    border-bottom: 2px solid rgba($blueDyrty, .5);
    color: $blueDyrty;
}

.list-paket-home ul li:hover {
    background-color: $turque;
    color: $white;
}