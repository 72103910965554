@mixin bgImage {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: initial;
}
@mixin bgImageFixed {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    background-attachment: fixed;
}